import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hi-im-khoa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hi-im-khoa",
        "aria-label": "hi im khoa permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hi! I'm Khoa`}</h1>
    <p>{`I'm a software engineer. I was born in 1987 (you can do the math)
and grew up in Middle Vietnam. I have first job about develop software from 03-2009.`}</p>
    <p>{`Now I am a Technical Manager at ORO Viet Nam. I've written code for:`}</p>
    <ul>
      <li parentName="ul">{`PRECITA`}</li>
      <li parentName="ul">{`ICare Benefits`}</li>
      <li parentName="ul">{`VietNamWorks`}</li>
      <li parentName="ul">{`Gianty`}</li>
      <li parentName="ul">{`Some Other Companies`}</li>
    </ul>
    <p>{`I love to work with cutting edge technical and I also love to build products that help people improve their life.
I spent sometimes to contribute to opensource world included `}<a parentName="p" {...{
        "href": "https://mattermost.org/"
      }}>{`Mattermost`}</a>{`, `}<a parentName="p" {...{
        "href": "https://magento.github.io/pwa-studio/"
      }}>{`Magento PWA`}</a>{`.`}</p>
    <p>{`My mainly experience about PHP. In last 4 years, I have done 3 Magento Ecommerce Websites and I will continue invest my time to Ecommerce field.`}</p>
    <p>{`Contact me via email `}<a parentName="p" {...{
        "href": "mailto:khoaln6@gmail.com"
      }}>{`khoaln6@gmail.com`}</a>{` if you need anything that I can help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      